import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import Integrantes from "../informacion/integrantes";
import Pasantes from "../informacion/pasantes";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../styles/integrantedescription.css";
import abogado1 from "../imagenes/abogado1.jpeg";
import abogado2 from "../imagenes/abogado2.jpeg";

import integrante1 from "../imagenes/jorgefoto2.jpeg";
import integrante2 from "../imagenes/alefoto2.jpeg";
import integrante3 from "../imagenes/grandefos.jpeg";
import integrante4 from "../imagenes/anabella.jpeg";
import integrante5 from "../imagenes/crisdos.jpeg";
import integrante6 from "../imagenes/castro2.jpeg";
import integrante7 from "../imagenes/castro2.jpeg";
import integrante8 from "../imagenes/hugofotodos.jpeg";
import pasante from "../imagenes/juampa2.jpeg";
import siluetaPasante from "../imagenes/pasante.png";

function useScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top:550,
      behavior: "smooth",
    });
  }, [location]); // This ensures scroll happens on route change
}

function RightPasante(id, nombre, titulo, desc, desc2) {
  if (id === 1) {
    return (
      <div className="center-div">
        <div className="integrante-description-container-outer-div">
          <div className="integrante-description-container">
            <h1 className="integrante-titulo-nombre">{nombre}</h1>
            <h2 className="informacion-integrante">
              Profesion: <b>{titulo}</b>
            </h2>
            <h2 className="informacion-integrante">
              Estudios Realizados En: <b>{desc}</b>
            </h2>
            <h2 className="informacion-integrante">
              Especializado En: <b>{desc2}</b>
            </h2>
          </div>
          <div className="image-container-integrante-description">
            <img className="imagen-abogado" src={pasante} />
          </div>
        </div>
      </div>
    );
  } else if (id == 2) {
    return (
      <div className="center-div">
        <div className="integrante-description-container-outer-div">
          <div className="integrante-description-container">
            <h1 className="integrante-titulo-nombre">{nombre}</h1>
            <h2 className="informacion-integrante">{titulo}</h2>
            <h2 className="informacion-integrante"></h2>
            <h2 className="informacion-integrante">{desc2}</h2>
          </div>
          <div className="image-container-integrante-description">
            <img className="imagen-abogado" src={siluetaPasante} />
          </div>
        </div>
      </div>
    );
  }
}

function RightIntegrante(id, nombre, titulo, desc, desc2, imgsrc, imgName) {
  if (id === 1) {
    return (
      <div className="center-div">
        <div className="integrante-description-container-outer-div">
          <div className="integrante-description-container">
            <h1 className="integrante-titulo-nombre">{nombre}</h1>
            <h2 className="informacion-integrante">
              Profesion: <b>{titulo}</b>
            </h2>
            <h2 className="informacion-integrante">
              Estudios Realizados En: <b>{desc}</b>
            </h2>
            <h2 className="informacion-integrante">
              Especializado En: <b>{desc2}</b>
            </h2>
          </div>
          <div className="image-container-integrante-description">
            <img className="imagen-abogado" src={integrante1} />
          </div>
        </div>
      </div>
    );
  } else if (id === 2) {
    return (
      <div className="center-div">
        <div className="integrante-description-container-outer-div">
          <div className="integrante-description-container">
            <h1 className="integrante-titulo-nombre">{nombre}</h1>
            <h2 className="informacion-integrante">
              Profesion: <b>{titulo}</b>
            </h2>
            <h2 className="informacion-integrante">
              Estudios Realizados En: <b>{desc}</b>
            </h2>
            <h2 className="informacion-integrante">
              Especializado En: <b>{desc2}</b>
            </h2>
          </div>
          <div className="image-container-integrante-description">
            <img className="imagen-abogado" src={integrante2} />
          </div>
        </div>
      </div>
    );
  } else if (id === 3) {
    return (
      <div className="center-div">
        <div className="integrante-description-container-outer-div">
          <div className="integrante-description-container">
            <h1 className="integrante-titulo-nombre">{nombre}</h1>
            <h2 className="informacion-integrante">
              Profesion: <b>{titulo}</b>
            </h2>
            <h2 className="informacion-integrante">
              Estudios Realizados En: <b>{desc}</b>
            </h2>
            <h2 className="informacion-integrante">
              Especializado En: <b>{desc2}</b>
            </h2>
          </div>
          <div className="image-container-integrante-description">
            <img className="imagen-abogado" src={integrante3} />
          </div>
        </div>
      </div>
    );
  } else if (id === 4) {
    return (
      <div className="center-div">
        <div className="integrante-description-container-outer-div">
          <div className="integrante-description-container">
            <h1 className="integrante-titulo-nombre">{nombre}</h1>
            <h2 className="informacion-integrante">
              Profesion: <b>{titulo}</b>
            </h2>
            <h2 className="informacion-integrante">
              Estudios Realizados En: <b>{desc}</b>
            </h2>
            <h2 className="informacion-integrante">
              Especializado En: <b>{desc2}</b>
            </h2>
          </div>
          <div className="image-container-integrante-description">
            <img className="imagen-abogado" src={integrante4} />
          </div>
        </div>
      </div>
    );
  } else if (id === 5) {
    return (
      <div className="center-div">
        <div className="integrante-description-container-outer-div">
          <div className="integrante-description-container">
            <h1 className="integrante-titulo-nombre">{nombre}</h1>
            <h2 className="informacion-integrante">
              Profesion: <b>{titulo}</b>
            </h2>
            <h2 className="informacion-integrante">
              Estudios Realizados En: <b>{desc}</b>
            </h2>
            <h2 className="informacion-integrante">
              Especializado En: <b>{desc2}</b>
            </h2>
          </div>
          <div className="image-container-integrante-description">
            <img className="imagen-abogado" src={integrante5} />
          </div>
        </div>
      </div>
    );
  } else if (id === 6) {
    return (
      <div className="center-div">
        <div className="integrante-description-container-outer-div">
          <div className="integrante-description-container">
            <h1 className="integrante-titulo-nombre">{nombre}</h1>
            <h2 className="informacion-integrante">
              Profesion: <b>{titulo}</b>
            </h2>
            <h2 className="informacion-integrante">
              Estudios Realizados En: <b>{desc}</b>
            </h2>
            <h2 className="informacion-integrante">
              Especializado En: <b>{desc2}</b>
            </h2>
          </div>
          <div className="image-container-integrante-description">
            <img className="imagen-abogado" src={integrante6} />
          </div>
        </div>
      </div>
    );
  } else if (id === 7) {
    return (
      <div className="center-div">
        <div className="integrante-description-container-outer-div">
          <div className="integrante-description-container">
            <h1 className="integrante-titulo-nombre">{nombre}</h1>
            <h2 className="informacion-integrante">
              Profesion: <b>{titulo}</b>
            </h2>
            <h2 className="informacion-integrante">
              Estudios Realizados En: <b>{desc}</b>
            </h2>
            <h2 className="informacion-integrante">
              Especializado En: <b>{desc2}</b>
            </h2>
          </div>
          <div className="image-container-integrante-description">
            <img className="imagen-abogado" src={integrante7} />
          </div>
        </div>
      </div>
    );
  } else if (id === 8) {
    return (
      <div className="center-div">
        <div className="integrante-description-container-outer-div">
          <div className="integrante-description-container">
            <h1 className="integrante-titulo-nombre">{nombre}</h1>
            <h2 className="informacion-integrante">
              Profesion: <b>{titulo}</b>
            </h2>
            <h2 className="informacion-integrante">
              Estudios Realizados En: <b>{desc}</b>
            </h2>
            <h2 className="informacion-integrante">
              Especializado En: <b>{desc2}</b>
            </h2>
          </div>
          <div className="image-container-integrante-description">
            <img className="imagen-abogado" src={integrante8} />
          </div>
        </div>
      </div>
    );
  }
}

function IntegranteDescription({ tipo }) {
  const navigate = useNavigate();
  const { id } = useParams();
  useScrollToTop();


  const integrante =
    tipo === "miembro" ? Integrantes[id - 1] : Pasantes[id - 1];

  return (
    <div>
      {tipo === "miembro" ? (
        <>
          {RightIntegrante(
            integrante.id,
            integrante.nombre,
            integrante.titulo,
            integrante.desc,
            integrante.desc2
          )}
        </>
      ) : (
        <>
          {RightPasante(
            integrante.id,
            integrante.nombre,
            integrante.desc,
            integrante.titulo,
            integrante.desc2
          )}
        </>
      )}
    </div>
  );
}

export default IntegranteDescription;
