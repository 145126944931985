import React from "react";
import { useParams } from "react-router-dom";
import "../styles//oficinadescription.css";
import Oficinas from "../informacion/oficinasFisicasLista";
import MapsComponent from "./MapsComponent";
import estudioblasetticalle14 from "../imagenes/estudioblasetticalle14.jpeg";
import estudioblasettioficinacitybell1 from "../imagenes/estudioblasettioficinacitybell1.jpeg";
import estudioblasettioficinacitybell2 from "../imagenes/estudioblasettioficinacitybell2.jpeg";
import estudioblasettioficinacitybell3 from "../imagenes/estudioblasettioficinacitybell3.jpeg";
import oficina2 from "../imagenes/oficina2.jpeg";
import oficina3 from "../imagenes/oficina3.jpg";
import oficinaVirtual from "../imagenes/oficinavirtual.jpeg";
import "../styles/oficinafisica.css";
function RightOficina(id, titulo, nombre, desc, direccion) {
  if (id === 1) {
    //estamos trabajando el style con la primer ofi
    return (
      <div className="outside-div-individual-office">
        <div className="sides-office">
          <div className="izquierda-side-ind-off">
            <div>
              <h1 className="titulo-oficina">{nombre}</h1>
              <h1 className="descripcion-oficina">{desc}</h1>
            </div>
          </div>
          <div className="derecha-side-ind-off">
            <div className="horarios-div-outside">
              <h2 className="horarios-title">Horarios</h2>
              <div className="horarios-div">
                <div className="dias-div">
                  <h2 className="dia-horario">Lunes</h2>
                  <h2 className="dia-horario">Martes</h2>
                  <h2 className="dia-horario">Miercoles</h2>
                  <h2 className="dia-horario">Jueves</h2>
                  <h2 className="dia-horario">Viernes</h2>
                </div>
                <div className="horarios-div-horas">
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                </div>
              </div>
            </div>
            <div className="maps-div">
              <MapsComponent lat={-34.919998} lng={-57.959182} />
            </div>
          </div>
          <div className="imagen-container">
            <img className="indiv-image" src={oficina2} />
            <img className="indiv-image" src={estudioblasetticalle14} />
            <img className="indiv-image" src={oficina2} />
          </div>
        </div>
      </div>
    );
  } else if (id === 2) {
    return (
      <div className="outside-div-individual-office">
        <div className="sides-office">
          <div className="izquierda-side-ind-off">
            <div>
              <h1 className="titulo-oficina">{nombre}</h1>
              <h1 className="descripcion-oficina">{desc}</h1>
            </div>
          </div>
          <div className="derecha-side-ind-off">
            <div className="horarios-div-outside">
              <h2 className="horarios-title">Horarios</h2>
              <div className="horarios-div">
                <div className="dias-div">
                  <h2 className="dia-horario">Lunes</h2>
                  <h2 className="dia-horario">Martes</h2>
                  <h2 className="dia-horario">Miercoles</h2>
                  <h2 className="dia-horario">Jueves</h2>
                  <h2 className="dia-horario">Viernes</h2>
                </div>
                <div className="horarios-div-horas">
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                </div>
              </div>
            </div>
            <div className="maps-div">
              <MapsComponent lat={-34.8698767} lng={-58.1402912} />
            </div>
          </div>
          <div className="imagen-container">
            <img
              className="indiv-image"
              src={estudioblasettioficinacitybell3}
            />
            <img
              className="indiv-image"
              src={estudioblasettioficinacitybell1}
            />
            <img
              className="indiv-image"
              src={estudioblasettioficinacitybell2}
            />
          </div>
        </div>
      </div>
    );
  } else if (id === 3) {
    return (
      <div className="outside-div-individual-office">
        <div className="sides-office">
          <div className="izquierda-side-ind-off">
            <div>
              <h1 className="titulo-oficina">{nombre}</h1>
              <h1 className="descripcion-oficina">{desc}</h1>
            </div>
          </div>
          <div className="derecha-side-ind-off">
            <div className="horarios-div-outside">
              <h2 className="horarios-title">Horarios</h2>
              <div className="horarios-div">
                <div className="dias-div">
                  <h2 className="dia-horario">Lunes</h2>
                  <h2 className="dia-horario">Martes</h2>
                  <h2 className="dia-horario">Miercoles</h2>
                  <h2 className="dia-horario">Jueves</h2>
                  <h2 className="dia-horario">Viernes</h2>
                </div>
                <div className="horarios-div-horas">
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                  <h2 className="dia-horario">9:00 a 17:00</h2>
                </div>
              </div>
            </div>
            <div className="maps-div">
              <MapsComponent lat={-34.936415} lng={-58.037297} />
            </div>
          </div>
          <div className="imagen-container">
            <img className="indiv-image" src={oficina2} />
            <img className="indiv-image" src={oficina2} />
            <img className="indiv-image" src={oficina2} />
          </div>
        </div>
      </div>
    );
  } else if (id === 4) {
    return (
      <div className="outside-div-individual-office">
        <div className="sides-office">
          <div className="izquierda-side-ind-off">
            <div>
              <h1 className="titulo-oficina">{nombre}</h1>
              <h1 className="descripcion-oficina">{desc}</h1>
            </div>
          </div>
          <div className="imagen-container">
            <img className="indiv-image" src={oficinaVirtual} />
          </div>
        </div>
      </div>
    );
  }
}

const OficinaDescription = () => {
  const { id } = useParams();
  const oficinas = Oficinas[id - 1];

  return (
    <>
      {RightOficina(
        oficinas.id,
        oficinas.titulo,
        oficinas.nombre,
        oficinas.desc,
        oficinas.direccion
      )}
    </>
  );
};

export default OficinaDescription;
