import React, { useState } from "react";
import "../styles/difusion.css";
import YoutubeVideos from "./YoutubeVideos";
import PDFDownloadButton from "./PDFDownloadButton";
import pdfFile from "../pdfs/VulnerablesEstudioBlasetti.pdf";
import Blog from "./Blog";

function Difusion() {
  const [selectedCategory, setSelectedCategory] = useState("Ver Todo");

  const pdfFileName = "Vulnerables-Estudio-Blasetti.pdf";

  const handleOptionChange = (event) => {
    selectedCategory(event.target.value);
  };

  return (
    <div className="container-difusion">
      <h1>Divulgación</h1>

      <div className="botones-blog">
        <button           className={`boton-elegir-tema ${selectedCategory === "Ver Todo" ? "selected" : ""}`}
 onClick={() => setSelectedCategory("Ver Todo")}>
          Ver Todo
        </button>
        <button           className={`boton-elegir-tema ${selectedCategory === "Derecho Animal" ? "selected" : ""}`}
 onClick={() => setSelectedCategory("Derecho Animal")}>
          Derecho Animal
        </button>
        <button           className={`boton-elegir-tema ${selectedCategory === "Derecho de Familia" ? "selected" : ""}`}
 onClick={() => setSelectedCategory("Derecho de Familia")}>
          Derecho de Familia
        </button>
        <button           className={`boton-elegir-tema ${selectedCategory === "Filosofia" ? "selected" : ""}`}
 onClick={() => setSelectedCategory("Filosofia")}>
          Filosofia
        </button>
        <button
                    className={`boton-elegir-tema ${selectedCategory === "Alquileres e Inmobiliario" ? "selected" : ""}`}
                    onClick={() => setSelectedCategory("Alquileres e Inmobiliario")}
        >
          Alquileres e Inmobiliario
        </button>
        <button           className={`boton-elegir-tema ${selectedCategory === "Niño, niña y Adolecentes" ? "selected" : ""}`}
 onClick={() => setSelectedCategory("Niño, niña y Adolecentes")}>
          Niño, niña y Adolecentes
        </button>

        {/* Agrega más botones según sea necesario */}
      </div>

      {selectedCategory === "Ver Todo" && (
        <>
          <Blog category={selectedCategory} />
          <h2>Charla sobre el derecho animal</h2>
          <YoutubeVideos />
          {/* Agrega aquí más contenido que corresponda a "Ver Todo" */}
        </>
      )}

      {selectedCategory === "Derecho Animal" && (
        <>
          <Blog category={selectedCategory} />
          <h2>Charla sobre el derecho animal</h2>
          <YoutubeVideos />

          {/* Agrega aquí más contenido que corresponda a "Derecho Animal" */}
        </>
      )}

      {selectedCategory === "Derecho de Familia" && (
        <>
          <Blog category={selectedCategory} />
        </>
      )}

      {/* Añade más condicionales según las opciones que necesites */}
    </div>
  );
}

export default Difusion;
