import React, { useState } from "react";
import "../styles/navbar.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { CiUser } from "react-icons/ci";

import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

function Navbar(props) {
  const navRef = useRef();

  const scrollToTop = () => {
    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });
  };
  //const hideNavbar =() => {
  // navRef.current.classList.toggle("responsive_nav");
  //}

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  // Call this function to hide the navbar
  const hideNavbar = () => {
    scrollToTop();
    navRef.current.classList.remove("responsive_nav");
  };

  return (
    <header className="header-div">
      <div className="out-div-nav-bar">
        <div className="navigation-bar" style={props.style}>
          {/*<div className="logo">
            <div className="img-logo">
              <Link to="/">
                <img className="imagen-agrandar-drop" src={props.imagen} />
              </Link>
            </div>
          </div>
  */}
          <nav ref={navRef} className="menu">
            <Link className="menu-text" to="/servicios" onClick={hideNavbar}>
              SERVICIOS
            </Link>
            <Link className="menu-text" to="/miembros" onClick={hideNavbar}>
              MIEMBROS
            </Link>
            <Link className="menu-text" to="/oficinas" onClick={hideNavbar}>
              OFICINAS
            </Link>

            <Link
              className="menu-text"
              to="https://wa.me/+5492214654039"
              onClick={hideNavbar}
            >
              <a
                href="https://wa.me/+5492214654039"
                className="whatsapp-icon-menu"
                target="_blank"
              >
                {" "}
                <FaWhatsapp className="whatsapp-icon-navbar" />
              </a>
            </Link>
            <Link className="menu-text" to="/divulgacion" onClick={hideNavbar}>
              DIVULGACIÓN
            </Link>
            <Link className="menu-text" to="/vinculados" onClick={hideNavbar}>
              VINCULADOS
            </Link>
            <Link className="menu-text" to="/sanivo" onClick={hideNavbar}>
              SAN IVO
            </Link>
            <Link className="menu-text" to="/usuario" onClick={hideNavbar}>
              <CiUser className="user-icon-navbar" size={30}/>
            </Link>
            <div className="nav-btn-div">
              <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                <FaTimes />
              </button>
            </div>
          </nav>

          <button className="nav-btn" onClick={showNavbar}>
            <FaBars />
          </button>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
