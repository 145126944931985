import React, { Suspense, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import generateServiciosList from "../informacion/serviciosLista";
import { Navigate, useNavigate } from "react-router-dom";
import MercadoPago from "./MercadoPago";
import "../styles/serviciodescription.css";
import ServicioOpcion from "./ServicioOpcion";
import LawyerMatcher from "./LawyerMatch";
import RightServicio from "./RightServicio";


function ServicioDescription() {
  const { id } = useParams();
  const [servicio, setServicio] = useState(null);
  const servicioId = parseInt(id, 10);
  useEffect(() => {
    const fetchServicios = async () => {
      const serviciosList = await generateServiciosList();
      const foundServicio = serviciosList.find(
        (s) => s.id === servicioId
      );
      setServicio(foundServicio);
    };

    fetchServicios();
  }, [id]);
  if (!servicio) {
    return <div>No se encontro el servicio, o se esta buscando.</div>;
  }

  return (
    <div className="servicio-description-div">
      <RightServicio
        id={servicio.id}
        title={servicio.title}
        descripcion={servicio.descripcion}
        precio={servicio.precio}
        precio2={servicio.precio2}
      />
    </div>
  );
}

export default ServicioDescription;