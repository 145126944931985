import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Sobrenosotros from "./components/Difusion";
import Miembros from "./components/Miembro";
import Integrantes from "./components/Integrantes";
import Servicios from "./components/Servicios";
import Oficinavirtual from "./components/Oficinavirtual";
import Footer from "./components/Footer";
import Whatsappbutton from "./components/WhatsappButton";
import IntegranteDescription from "./components/IntegranteDescription";
import ServicioDescription from "./components/ServicioDescription";
import MercadoPago from "./components/MercadoPago";
import Success from "./components/Sucess";
import Oficinas from "./components/Oficinas";
import OficinaDescription from "./components/OficinaDescription";
import Slideshow from "./components/Slideshow";
import IndBlog from "./components/IndBlog";
import Usuario from "./components/Usuario";
import Vinculados from "./components/Vinculados";
import Sanivo from "./components/Sanivo";
import ReactGA from "react-ga4";


const TRACKING_ID = "G-CL7WMN75JE";
ReactGA.initialize(TRACKING_ID);


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div className="outer-div-website sobre-nosotros">
                <Slideshow />
                <Navbar />
                <Home />
                <Footer />
              </div>
            }
          />
          <Route
            path="/home"
            element={
              <div className="outer-div-website home">
                <Slideshow />
                <Navbar />
                <Home />
                <Footer />
              </div>
            }
          />
          <Route
            path="/divulgacion"
            element={
              <div className="outer-div-website sobre-nosotros">
                <Slideshow />
                <Navbar />
                <Sobrenosotros />
                <Footer />
              </div>
            }
          />
          <Route
            path="/miembros"
            element={
              <div className="outer-div-website sobre-nosotros">
                <Slideshow />
                <Navbar />
                <Integrantes />
                <Footer />
              </div>
            }
          />
          <Route
            path="/servicios"
            element={
              <div className="outer-div-website sobre-nosotros">
                <Slideshow />
                <Navbar />
                <Servicios />
                <Footer />
              </div>
            }
          />
          {/*
                      <Route
            path="/oficina-virtual"
            element={
              <div className="outer-div-website sobre-nosotros">
                <Slideshow />
                <Navbar />
                <Oficinavirtual />
                <Footer />
              </div>
            }
          />
            */}

          <Route
            path="/miembro/:id"
            element={
              <div>
                <Slideshow />
                <Navbar />
                <IntegranteDescription tipo={"miembro"} />
                <Footer />
              </div>
            }
          />
          <Route
            path="/pasante/:id"
            element={
              <div>
                <Slideshow />
                <Navbar />
                <IntegranteDescription tipo={"text"} />
                <Footer />
              </div>
            }
          />
          <Route
            path="/servicio/:id"
            element={
              <div>
                <Slideshow />
                <Navbar />
                <ServicioDescription />
                <Footer />
              </div>
            }
          />
          <Route
            path="/oficina/:id"
            element={
              <div>
                <Slideshow />
                <Navbar />
                <OficinaDescription />
                <Footer />
              </div>
            }
          />
          <Route
            path="/blog/:id"
            element={
              <div className="App">
                <Slideshow />
                <Navbar />
                <Whatsappbutton />
                <IndBlog />
                <Footer />
              </div>
            }
          />
          <Route
            path="/mercadopago"
            element={
              <div>
                <MercadoPago />
              </div>
            }
          />
          <Route
            path="/success"
            element={
              <div>
                <Slideshow />
                <Navbar />
                <Success />
                <Footer />
              </div>
            }
          />
          <Route
            path="/oficinas"
            element={
              <div>
                <Slideshow />
                <Navbar />
                <Oficinas />
                <Footer />
              </div>
            }
          />
          <Route
            path="/usuario"
            element={
              <div>
                <Slideshow />
                <Navbar />
                <Usuario />
                <Footer />
              </div>
            }
          />
                    <Route
            path="/vinculados"
            element={
              <div>
                <Slideshow />
                <Navbar />
                <Vinculados/>
                <Footer />
              </div>
            }
          />
                              <Route
            path="/sanivo"
            element={
              <div>
                <Slideshow />
                <Navbar />
                <Sanivo />
                <Footer />
              </div>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
