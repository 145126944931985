import React from "react";
import Blogs from "../informacion/blogs";
import { Navigate, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../styles/indblog.css";

function MostrarBlog(id, titulo, contenido) {
  if (id === 1) {
    return (
      <div className="ind-blog-result">
        <h1 className="blog-titulo">{titulo}</h1>
        <h2 className="subtitulo-blog">
          Letras para el Sexto Congreso de Ciencias Jurídicas C.A.L.P. 21 y 22
          /III/2024
        </h2>
        <h2 className="subtitulo-blog">Comisión IV Subcomisión 2</h2>
        <p className="parrafo-blog">
          En los tiempos que corren, el concepto de vulnerabilidad, es utilizado
          para justificar cualquier asimetría prestacional, en el cuidado de los
          grupos discriminados. La etimología de la palabra del latín
          vulnerabilis. (vulnus, significa 'herida', y el sufijo-abilis, indica
          posibilidad) Nos lleva a su significado mayor probabilidad de ser
          herido. Los Vulnerables son aquellos que tiene mayor posibilidad de
          ser heridos. Esto, nos invita a pensar, ¿Por qué, tal o tales tienen
          mayor posibilidad de ser heridos?, ¿Qué, consecuencias tiene la
          herida?,¿Quién o quienes la infligen?, y ¿Por qué, no vamos contra él
          o ellos, en vez de intentar escudar o sanar al vulnerable? Las
          respuestas no son sencillas, pero la especie dominante, no ha llegado
          hace mucho a la cúspide de la cadena alimentaria, abatiendo predadores
          y modificando en entorno, para garantizar su subsistencia. Este
          ascenso, fue impulsado por el miedo al entorno, tejido de sangre, y
          consolidado con muros, esta vez construidos, no encontrados como las
          cavernas. Es ese miedo ancestral a lo desconocido, que nos impulsa a
          conocer primero, y a dominar a lo conocido después, para que la
          posibilidad de que se vuelva contra nosotros se elimine o se reduzca,
          es el impulso del homo sapiens. En el Matadero, cuento de Esteban
          Echeverria, el torro que por algún motivo estaba en la tropilla de
          novillos, llevados a palear el hambre de la carne (que en los días de
          cuaresma era destinada a niños, ancianos y enfermos – vulnerables –
          pero que fuera apetecida por todos), ante la inminencia de su
          sacrificio, lucha y en su huida decapita un niño con el tiento con que
          se lo sujetaba y azuzaba para caminar. Ergo, ese animal, representa lo
          salvaje, lo indómito, no era de los llevados al lugar del sacrificio,
          por ser justamente un animal peligroso, pero habido, en tal sitio, es
          primero reconocido, e indagado si se trataba de tal, y visto, es
          intentado dominar sin éxito en primer término, para ser luego por el
          trabajo colectivo, asirlo de una pata, cortarle el garrón, dominado y
          despostado. Así como al joven unitario, pero esa es otra historia, que
          aunque podríamos hacer propia sobre como los sapiens se discriminan y
          se vulneran entre sí, quedara para una versión ampliada del presente.
          En el relato, las características de la especie muestran su
          animalidad, y su capacidad de triunfo, su capacidad de herir y de
          sanar. Es por eso que no vamos contra quien infringe la herida porque
          nos resulta mucho más difícil, ir contra nosotros, contra nuestros
          miedos, contra nuestros impulsos, pero viendo el alcance de nuestras
          acciones, tratamos, ulteriormente de evitar el daño o subsanarlo. Por
          año aproximadamente se extinguen por distintos motivos 70.mil especies
          solo de animales, sin contar los vegetales y desequilibrios
          ambientales, y podríamos también hablar de las innumerables bajas de
          la especie homo sapiens también. No toda, pero la mayor de la causa de
          extinción, es provocada por el poder alcanzado por este otro animal,
          que caza ya en menor escala, porque produce su propia caza cerca de
          casa, pero que modifica el entorno adueñándose del sustrato a
          compartir. Esta modificación al entorno y expansión en un número
          creciente, sin depredadores naturales lo lleva en el siglo XXI a
          escalas impensables, que llega a las fosas basales con sus
          desperdicios y supera los límites del planeta con sus inventos.Pese a
          que puede ser victima de enfermedades, va a por ellas, con singular
          éxito. El homo sapiens, es sin duda el mayor predador, y quien pone en
          condición de ser herido todo lo que está por fuera de sí, e incluso
          ese sí mismo, sí entendiéramos a la especie como homogénea. No nos
          toca a nosotros esta discriminación, sino el ver el alcance de nuestra
          potencial herida sobre las especies no sapiens, o no homo como me
          gusta decir, re significando el termino animal no humano, por animal
          no homo, ya que el humus de lo humano, es el eslabón que nos une con
          el todo terrestre, los reinos de seres vivos: animales, vegetales,
          fungi (hongos), protoctista (protozoos y algas) y monera (bacterias) y
          el ambiente. La acción tuitiva puede adquirir muchas formas, algunas
          incluso extremas, como la de desertar que propone un filosofo
          activista italiano Bifo Berardi, con el que coincido solo en algunas
          provocaciones, el propone desertar de la tecnología, de las ciudades y
          de la natalidad, para reducir las víctimas del impacto del hombre,
          como una autonomía creativa del tiempo que viene, él lo dice en vistas
          a la lucha contra el capitalismo que depreda todo, o hacer disidencia
          que propone el francés Erc Sadin, desaprobar el modelo, asumir la
          responsabilidad política cada día, no solo el día del voto; revisar
          las micro decisiones: evitar avalar las nuevas formas de poder el
          comercio electrónico y globalización de servicios que consolidad las
          relaciones de poder; dejar de lado la adhesión a los discursos
          formateados, construir una sociedad critica y evitar la confiscación
          de la palabra, y las formas, evitar las redes; ejercitar la
          interposición, manifestaciones en masa de los principios de integridad
          y dignidad; institucionalizar la alternativa, desde el compromiso
          político, reapropiarse del poder delegado y ejercerlo; resistir la
          tecnología que nos individualiza a extremos impensados, cuando la
          competencia ya no es el otro que hace lo mismo que yo, sino el dominar
          el tiempo del otro por encima de sus necesidades vitales, reducción de
          sueño, reducción de espacios de silencio, reducción de los espacios de
          reunión, estando allí, en almuerzos, citas e incluso frente a los
          medios, el multimedio. Pero que ambas requieren una acción colectiva,
          de difícil producción. Hace pocos días acá en La Plata, clausuraron al
          menos temporalmente dos locales, WordlCoin que pagaban por datos
          biométricos de las personas, accederse a si mismos, algo así como
          vender la llave de tu propia casa… y estamos dispuestos a cobrar
          datos, pero tambien, los regalamos en cada interacción de e commerce,
          o por una promoción, a que todos llevan consigo mas dinero digital que
          físico y que representa ya ese dinero… bueno, no les arruinare la
          mañana con esto. Ante lo basto del alcance del daño, nos detendremos
          en algunos mínimos tópicos para el análisis. ¿Qué podemos hacer como
          abogados? y un análisis mínimo de la vulneración que sufren los ANH?
          Como abogados, tenemos el mecanismo de la ley, para ejercer acciones
          positivas e imperativas, como forma de poner e imponernos límites. En
          un estado de derecho, la posibilidad de la construcción normativa por
          vía legislativa y jurisdiccional, es la herramienta para evitar la
          profusión y el impacto de los daños provocados por la acción del
          hombre, en su mayoría, son daños indirectos, no dirigidos directamente
          contra los no homine, sino que son en el afán de la consolidación de
          la “especie” o de algunos individuos de la especie. Por ello, en
          primer término, debemos conocer las normas disponibles y el alcance de
          la mismas, en la relación Hombre, resto de los ANH, dar estatus
          jurídico a los ANH, para que, sobre ese estatus, puedan defender los
          derechos que le son reconocidos en el presente y los que deberán ser
          reconocidos en el futuro. Exigir, ante la justicia, la administración
          y por los medios de participación democrática al poder legislativo, el
          reconocer a los ANH como sujeto de derecho. (posteriormente a esta
          ponencia, veremos un poco más sobre este tema, en el ámbito
          educacional y en el plano constitucional) En un segundo ara ello, y
          siguiendo en orden a nuestros vulnerables de hoy día, las animales no
          homo.Avanzando sobre las cuestiones y para ir finalizando, cuales son
          las principales vulnerabilidades de los ANH Según que tipo de animales
          se trate, dado nuestro siempre afán de clasificar: Tenemos la
          distinción entre salvajes, liminares y domesticados o de consumo, me
          permito usar de consumo para incluso los de compañía, ya que sirven a
          nuestro consumo emocional. El reconocimiento de vulnerables, implica
          un primer reconocimiento, y distinguirlos, nos ayuda a protegerlos, y
          entender desde donde protegerlos. La mirada proteccionista puede ser
          antropocéntrica, los protegemos porque son importantes para nosotros,
          o no antropocéntrica, evito hablar aquí de perspectiva animal, porque
          es un punto flaco sujeto a la crítica a mi ningún perro me ha dicho
          cuál es su mirada, aunque muchos podamos entenderles la mirada, como
          la bravura del toro del matadero. La mirada no antropocéntrica,
          importa un salir de sí, en búsqueda de lo otro, lo diferente, pero no
          por diferente, un completo extraño e indigno, ya que se han demostrado
          las grandes similitudes en el ADN de todos los animales, incluido el
          homo sapiens, y entendiendo a la dignidad como cualidad del homo
          sapiens por asignación propia. En tal tour, encontrar las semejanzas o
          diferencias según el rumbo que se pretenda dar al hallazgo, la
          sintiencia y el deseo, nos resultan un aparente, una que indica la
          capacidad de sufrir, ese sentir al dolor, pero también al cariño, y el
          deseo, esa aspiración incondicional a la subsistencia, ese instinto,
          conocimiento de lo que se quiere, vivir, comer, reproducirse, estar
          sano, y etc. utilizo este etc. por no ser un listado exhaustivo sino
          solo enunciativo, son relevantes. La ciencia, ese moderno vehículo
          para reconocer el ambiente, ha probado la existencia de ambas cosas en
          los animales, empero es la cultura la que debe reconocerlos como
          tales, así como reconoció el derecho a la libertad y al voto a ciertas
          características de la propia especie, hoy puede y debe, reconocer que
          tales cualidades, merecen la protección jurídica, por su sola
          condición (dignidad) y darles la calidad de sujetos de derecho. La
          calidad de personas, puede ser aun mas discutida, pero la condición de
          sujeto de derecho, con alcance de protección de los derechos que
          determine el proceso de conformación de la ley, es un paso
          significativo en la acción tuitiva, mantenerlos en otra condición,
          muestra la poca importancia que le damos a ese otro animal no homine.
          Me permito decir, como dije más arriba, que la ley como un mecanismo,
          de la ciencia un vehículo, pues hay otros que también nos permiten
          realizar el tour por otras vías, como la filosofía por citar alguno.
          Teniendo en cuenta estos deseos, en los diferentes estadios animales,
          serán los derechos a reconocer y proteger. En breve síntesis: (son
          solo algunos ejemplos disparadores que deben ser completados por uds.)
          Animales salvajes: (si no hubo intervención, no intervenir, la mejor
          forma de intervenir) preservar sus espacios naturales, en suficiencia
          y biodiversidad, con acceso a agua de calidad, sin introducir
          elementos exógenos que los alteren, restringir zonas de accionar homo
          sapiens, ya que con nosotros traemos animales exóticos, fauna
          microbiana, desperdicios, etc. Si ya hubo intervención, reparar, en lo
          posible las condiciones previas, cada especie tiene condiciones
          diferentes y deben ser analizadas y tenidas en cuenta. Animales
          liminares: cuidar los espacios de arraigo a los centros urbanos y
          suburbanos, ejemplo permitir el anidamiento, evitando colocación de
          elementos impedientes, garantizar la salubridad para sí y para el
          resto de las interacciones con los otros animales, propender a
          recuperar la condición de animales silvestres con políticas activas en
          tal sentido. La paloma, como el gorrión en la Argentina son animales
          exóticos y liminares, mantener la condición de tales, es la
          intervención adecuada en ámbitos donde no estén como adyacentes sus
          espacios naturales, en nuestro territorio, no existen tales. Animales
          domesticados, de consumo: cuidad y garantizar el bienestar, tratando
          de reducir el consumo, sustituyendo acciones (ejemplo las leyes de
          tracción animal, la sustitución de perros en detección de drogas,
          bombas, minas, etc. o como animales de cuidado de espacios,
          etc.)Subgrupo animales de compañía: cuidar y garantizar las
          condiciones de vida, respondiendo por la relación de cuidado,
          sanitaria, alimentaria y fundamentalmente no descuido, ni abandono y
          no hominización, conservación de su integridad física y racial. En el
          marco de legislaciones modernas y adaptativas, de esta forma, iremos
          reduciendo el grupo de los VULNERABLES
        </p>
      </div>
    );
  } else if (id === 2) {
    return (
      <div className="ind-blog-result">
        <h1 className="blog-titulo">{titulo}</h1>
        <h2 className="subtitulo-blog">Subtitulo ejemplo 2</h2>
      </div>
    );
  }
}

const IndBlog = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const blogs = Blogs[id - 1];
  return (
    <div className="container-blog">
      {MostrarBlog(blogs.id, blogs.titulo, blogs.contenido)}
    </div>
  );
};

export default IndBlog;
