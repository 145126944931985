import pasante from "../imagenes/juampafrontal.jpeg";
import siluetaPasante from "../imagenes/pasante.png";

const pasantesLista = [
  {
    id: 1,
    nombre: "Juan Pablo Mendez",
    titulo: "Estudiante de derecho",
    desc: `Fac. Cs. Juridicas y Sociales
    Univ. Nacional de La Plata`,
    desc2: `Pasante desde 2023`,
    imgName: pasante,
  },
  {
    id: 2,
    nombre: "Querés ser un pasante?",
    titulo: "Más información Acá!",
    desc: ` Si sos estudiante de derecho, con las materias procesales aprobadas, podés realizar una pasantía de aprendizaje en el estudio, envía un C.V. al número de Whatsapp del estudio y nos contactaremos con usted para una entrevista.`,
    desc2: ` Estamos buscando jóvenes interesados en obtener experiencia laboral `,
    imgName: siluetaPasante,
  },
];

export default pasantesLista;
