import React from 'react';
import '../styles/scrollingmarqueetext.css';

const ScrollingMarqueeText = () => {
    return (
        <div className="marquee-container">
            <div className="marquee">
                <div className="marquee__group">
                    <Phrases />
                </div>
                <div aria-hidden="true" className="marquee__group">
                    <Phrases />
                </div>
            </div>
            {
            /*
                <div className="marquee marquee--reverse">
                <div className="marquee__group">
                    <Phrases />
                </div>
                <div aria-hidden="true" className="marquee__group">
                    <Phrases />
                </div>
            </div>
            */}
        </div>
    );
};

const Phrases = () => (
    <>
        <h2 className="marquee-text">Abogados de Familia</h2>
        <h2 className="marquee-text">Derecho Animal</h2>
        <h2 className="marquee-text">Embargos</h2>
        <h2 className="marquee-text">Division de bienes</h2>
        <h2 className="marquee-text">Abogados de Familia</h2>
        <h2 className="marquee-text">Derecho Animal</h2>
        <h2 className="marquee-text">Embargos</h2>
        <h2 className="marquee-text">Division de bienes</h2>
    </>
);

export default ScrollingMarqueeText;
