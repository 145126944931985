import React from "react";
import "../styles/blog.css";
import blogs from "../informacion/blogs";
import { useNavigate } from "react-router-dom";

const Blog = ({ category }) => { // Accepting category as a prop
  const navigate = useNavigate();

  // Filter blogs based on the category prop
  const filteredBlogs = category === "Ver Todo" ? blogs : blogs.filter(blog => blog.categoria === category);

  return (
    <div className="outside-div-blog">
      <h1 className="blog-title">Blogs:</h1>
      <div className="div-outside-blogs">
        {filteredBlogs.map((blog) => {
          const { id, titulo } = blog;
          return (
            <div
              className="ind-blog"
              key={id}
              onClick={() => navigate("/blog/" + id)}
            >
              <h1 className="titulo-blogs">{titulo}</h1>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Blog;
