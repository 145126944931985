import React from "react";
import MercadoPago from "./MercadoPago"; // Assuming this is the path to your MercadoPago component
import LawyerMatcher from "./LawyerMatch";

const ServicioOpcion = ({
  selectedOption,
  precio,
  precio2,
  title,
  servicioId,
}) => {
  let precioFinal = selectedOption;
  let message =
    "Luego de pagar será redirigido a seleccionar su día y horario de turno.";

  if (precioFinal === "Presencial") {
    return (
      <div className="opcion-individual">
        <div>
          <h1 className="titulo-servicio">{selectedOption}</h1>
        </div>
        <div className="mc-div">
          <MercadoPago precio={precio} titulo={title} cantidad={1} />
        </div>
        <>
          <LawyerMatcher servicioSeleccionado={servicioId} />
        </>
        <h1 className="mensaje-inferior-servicio">{message}</h1>
      </div>
    );
  } else if (precioFinal === "Virtual") {
    return (
      <div className="opcion-individual">
        <div>
          <h1 className="titulo-servicio">{selectedOption}</h1>
        </div>
        <div className="mc-div">
          <MercadoPago precio={precio} titulo={title} cantidad={1} />
        </div>
        <h1 className="mensaje-inferior-servicio">{message}</h1>
      </div>
    );
  } else if (precioFinal === "Presencial Extendida") {
    return (
      <div className="opcion-individual">
        <div>
          <h1 className="titulo-servicio">{selectedOption}</h1>
        </div>
        <div className="mc-div">
          <MercadoPago precio={precio} titulo={title} cantidad={1} />
        </div>
        <h1 className="mensaje-inferior-servicio">{message}</h1>
      </div>
    );
  }
};

export default ServicioOpcion;
