import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "400px",
  height: "400px",
};

const center = {
  lat: -34.91999816894531,
  lng: -57.95918273925781,
};

function MapsComponent({lat,lng}) {
  const center = {
    lat: lat,
    lng: lng,
  };
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBpGUu4ZSfzOAf-FSZouRMCDTNwWkUIpFo" // Replace with your API key
    >
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
        <Marker position={center} title="Estudio Blasetti" />
      </GoogleMap>
    </LoadScript>
  );
}

export default MapsComponent;
