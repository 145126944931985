import Axios from "axios";
import actuacioninmobiliaria from "../imagenes/actuacioninmobiliaria.jpeg";
import actuacionnotarial from "../imagenes/actuacionnotarial.png";
import consultoriajuridica from "../imagenes/consultoriajuridica.jpg";
import contratos from "../imagenes/contratos.jpg";
import representacionjudicial from "../imagenes/representacionjudicial.png";
import serviciodeinformes from "../imagenes/serviciodeinformes.jpg";
//const BACKEND_URL = "https://drop-track.onrender.com";
const BACKEND_URL = "https://estudioblasetti.onrender.com";

const fetchIusValue = async () => {
  try {
    const response = await Axios.get(BACKEND_URL + '/api/valor-de-ius');
    return response.data.valorDeIUS;
  } catch (error) {
    console.error('Error fetching IUS value:', error);
    return 0; // Fallback to 0 or some default value in case of an error
  }
};
const generateServiciosList = async () => {
  const iu = await fetchIusValue();
  return [
    {
      id: 1,
      title: "Consultaría Jurídica",
      descripcion: `Consiste en una entrevista con un profesional de la rama del derecho que se adecue a su necesidad dentro de los miembros del estudio. 
       es el producto propuesto, la modalidad, se practicara en alguna de las sedes, en días y horarios coordinados previamente, habiéndose abonado el cargo del servicio por los medios de pago elegido.
      La entrevista versara sobre los problemas de consulta y tendrá como respuesta las posibilidades legales, judiciales y extra judiciales para hacer frente a su inquietud en forma oral o por escrito según el deseo del cliente, si se tratara de múltiples consultas que desean sean evacuadas por escrito, el costo será del canon de dos ius por cada una de las problemáticas que deseen ser evacuadas por escrito.
      Si el cliente desea acceder por la modalidad virtual, concertara una entrevista por Calendari y abonara la consulta por los medios de pago, se le remitirá un link y en el día y hora indicado, se practicara la entrevista como si fuera presencial.
      .`,
      descripcion2: "hola",
      precio: 2 * iu,
      precio2: 2.5 * iu,
      servicioFoto: consultoriajuridica,
    },
    {
      id: 2,
      title: "Representacion Judicial",
      descripcion: `Es la tarea propia del abogado, representar al cliente, presentando escritos y/o asistirlo en audiencias, en sede judicial o administrativa, en instancias de mediacion o de litigio, bajo las modalidades de Patrocinio: donde el cliente firma los escritos juridicos que le propone el letrado o Apoderamiento: donde el cliente encarga al letrado su caso dandole poder para actuar sin su firma ni supervision. 
    ◦ Procucuracion: Es la actividad professional de seguir un caso judicial en forma presencial o virtual, a diferencia de la actividad del abogado, el procurador, sigue la causa y le reporta al cliente el estado de la misma.`,
      descripcion2: "hola",
      precio: 3 * iu,
      servicioFoto: representacionjudicial,
    },
    {
      id: 3,
      title: "Contratos",
      descripcion: `Los contratos son acuerdos entre partes que se practican en persecución de un bien para cada una de ellas, por lo que es necesaria su -Evaluacion: en cuanto a su adecuacion al sistema juridico y del instrumento con tal para su major eficacia y eficiencia al bien deseado por la parte que encarga la actividad juridica.
    Tambien contamos con la posibilidad de practicar la Confeccion: del instrumento para lograr los fines que el cliente pretende adecuado en el plano juridico y  que albergue y satisfaga sus necesidades y deseos. `,
      descripcion2: "hola",
      precio: 3 * iu,
      servicioFoto: contratos,
    },

    {
      id: 4,
      title: "Proyecto para Actuacion Notarial",
      descripcion: `El asesoramiento juridico en instrumentos notariales, les da una perspectiva dinamica a la seguridad que el notariodo avala, ejemplos de esto es la confeccion de proyectos de distintos instrumentos para cesion de bienes muebles e inmuebles, o la Confeccion de Testamentos y demas instrumentos que tengan en miras la seguridad del notarial y la agilidad juridica.`,
      descripcion2: `hola`,
      precio: 3 * iu,
      servicioFoto: actuacionnotarial,
    },
    {
      id: 5,
      title: "Proyecto para Actuacion Inmobiliaria",
      descripcion: `El asesoramiento juridico en instrumentos inmobliarios, asegura la viabilidad de su inversion y facilita la confeccion de instrumentos y perfecciona el desarrollo de las ideas de negocios.`,
      descripcion2: "hola",
      precio: 3 * iu,
      servicioFoto: serviciodeinformes,
    },
    {
      id: 6,
      title: "Servicio de Informes",
      descripcion: `El tramite de los expedientes judiciales y administrativos debe ser revisado, para verificar la correcta direccion de los intereses en el transito procesal`,
      descripcion2: "hola",
      precio: 3 * iu,
      servicioFoto: actuacioninmobiliaria,
    },
  ];
};

export default generateServiciosList;
