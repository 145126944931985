import React, { useState, useEffect, useRef } from "react";
import "../styles/miembros.css";

function Miembros(props) {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(containerRef.current); // Stop observing after it becomes visible
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef]);

  return (
    <div className="outer-container-integrante">
      <div
        ref={containerRef}
        className={`container-centrador-dos ${isVisible ? "visible" : ""}`}
      >
        <div className="each-integrante">
        <div className="nombre-integrante-wrapper">
          <h1 className="nombre-integrante">{props.name}</h1>
         </div>
          <div className="imagen-integrante-div">
            <img src={props.imgName} className="imagenes-integrantes" />
          </div>
          <div className="titulo-u-profesion-wrapper">

          <h2 className="titulo-o-profesion">{props.titulo}</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Miembros;
