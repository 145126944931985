import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Miembro from "./Miembro";

import "../styles/integrante.css";

import integrantes from "../informacion/integrantes.js";
import pasantes from "../informacion/pasantes.js";
import pasantesLista from "../informacion/pasantes";

function Integrantes() {
  const [integrantesLista, setIntegrantesLista] = useState(integrantes);

  // Slicing the array
  const firstOne = integrantesLista.slice(0, 1);
  const consultores = integrantesLista.slice(1, 3);
  const responsablesDeOficina = integrantesLista.slice(3, 6);
  const secondHalf = integrantesLista.slice(6, 8);

  const navigate = useNavigate();

  return (
    <div className="container-centrador">
      <div className="integrantes-container">
        {/* Render the first half */}
        <div className="row-only-one">
          {firstOne.map((integrante) => {
            const { id, nombre, titulo, desc, imgName } = integrante;
            return (
              <div
                className="miembro-solo"
                key={id}
                onClick={() => navigate("/miembro/" + id)}
              >
                <Miembro
                  name={nombre}
                  titulo={titulo}
                  texto={desc}
                  imgName={imgName} // Assuming integrante4 is defined elsewhere
                />
              </div>
            );
          })}
        </div>

        <h1 className="titulo-miembros"> Consultores </h1>
        {/* Render the first half */}
        <div className="row">
          {consultores.map((integrante) => {
            const { id, nombre, titulo, desc, imgName } = integrante;
            return (
              <div key={id} onClick={() => navigate("/miembro/" + id)}>
                <Miembro
                  name={nombre}
                  titulo={titulo}
                  texto={desc}
                  imgName={imgName} // Assuming integrante4 is defined elsewhere
                />
              </div>
            );
          })}
        </div>
        <h1 className="titulo-miembros titulo-largo">
          {" "}
          Abogados Responsables de Oficinas{" "}
        </h1>
        {/* Render the first half */}
        <div className="row">
          {responsablesDeOficina.map((integrante) => {
            const { id, nombre, titulo, sedeResponsable, desc, imgName } =
              integrante;
            return (
              <div key={id} onClick={() => navigate("/miembro/" + id)}>
                <Miembro
                  name={nombre}
                  titulo={sedeResponsable}
                  texto={desc}
                  imgName={imgName} // Assuming integrante4 is defined elsewhere
                />
              </div>
            );
          })}
        </div>

        <h1 className="titulo-miembros"> Abogados de Especialidad </h1>
        {/* Render the first half */}
        <div className="row">
          {secondHalf.map((integrante) => {
            const { id, nombre, titulo, desc, imgName } = integrante;
            return (
              <div key={id} onClick={() => navigate("/miembro/" + id)}>
                <Miembro
                  name={nombre}
                  titulo={titulo}
                  texto={desc}
                  imgName={imgName} // Assuming integrante4 is defined elsewhere
                />
              </div>
            );
          })}
        </div>

        <h1 className="titulo-miembros"> Pasantes </h1>
        <div className="row">
          {pasantesLista.map((pasante) => {
            const { id, nombre, titulo, desc, imgName } = pasante;
            return (
              <div key={id} onClick={() => navigate("/pasante/" + id)}>
                <Miembro
                  name={nombre}
                  titulo={titulo}
                  texto={desc}
                  imgName={imgName} // Assuming integrante4 is defined elsewhere
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Integrantes;
