import React, { useRef, useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
import "../styles/generadorrecibos.css";

const GeneradorRecibos = () => {
    // Reference to the component that you want to print
    const componentRef = useRef();

    // State to store the current date
    const [currentDate, setCurrentDate] = useState('');

    // Function to handle the PDF generation
    const handlePrint = () => {
        if (componentRef.current) {
            // Configuration options for html2pdf
            const options = {
                margin: 10,
                filename: 'recibo.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            };
            // Generate the PDF from the referenced element
            html2pdf().from(componentRef.current).set(options).save();
        } else {
            console.error("Component reference is null during PDF generation.");
        }
    };

    // useEffect to set the current date when the component mounts
    useEffect(() => {
        const today = new Date();
        // Format the date to yyyy-mm-dd to match the input type="date"
        const formattedDate = today.toISOString().split('T')[0];
        setCurrentDate(formattedDate);
    }, []);

    return (
        <div>
            <h1>Generador de Recibos</h1>

            {/* The component to be printed */}
            <div ref={componentRef} style={{ border: '1px solid white', marginBottom: '30px', width: '700px'}}>
                <div className="receipt-container">
                    <div className="receipt-header">
                        <div className="law-office">
                            <h2>Estudio Jurídico</h2>
                            <h3>Blasetti</h3>
                            <p>Desde 1928</p>
                        </div>
                        <div className="receipt-title">
                            <h1>Recibo</h1>
                            <p>Solicita factura electrónica</p>
                        </div>
                    </div>

                    <div className="receipt-body">
                        <div className="left-section">
                            <div className="info-block">
                                <label>Contacto</label>
                                <input type="text" placeholder="Ingrese contacto" />
                                <label>Motivo</label>
                                <input type="text" placeholder="Ingrese motivo" />
                                <label>Fecha</label>
                                <input type="date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} />
                            </div>
                            <div className="signature-block">
                                <label>Sr/a</label>
                                <input type="text" placeholder="Ingrese nombre" />
                                <label>Pago $</label>
                                <input type="number" placeholder="Ingrese monto" />
                            </div>
                        </div>

                        <div className="right-section">
                            <label>Señor/a:</label>
                            <input type="text" placeholder="Ingrese nombre" />
                            <label>Domicilio/teléfono:</label>
                            <input type="text" placeholder="Ingrese domicilio o teléfono" />
                            <label>RECIBI LA SUMA DE PESOS:</label>
                            <input type="number" placeholder="Ingrese cantidad" />
                            <label>A IMPUTAR A :</label>
                            <input type="text" placeholder="Ingrese concepto" />

                            <div className="account-section">
                                <label>A cuenta de terceros $</label>
                                <input type="number" placeholder="Ingrese monto" />
                                <label>Honorarios $</label>
                                <input type="number" placeholder="Ingrese monto" />
                                <label>Total de pesos $</label>
                                <input type="number" placeholder="Ingrese total" />
                            </div>

                            <label>Firma por el Estudio</label>
                            <input type="text" placeholder="" />
                        </div>
                    </div>

                    <div className="receipt-footer">
                        <p>Domicilio: 14 no. 765 La Plata Tel/whatsapp:+54 9 221 4654039 estudioblasetti@gmail.com</p>
                    </div>
                </div>
            </div>

            {/* Button to trigger PDF generation */}
            <button onClick={handlePrint} className='generar-pdf-button'>Generar PDF</button>
        </div>
    );
};

export default GeneradorRecibos;
