import React from "react";
import "../styles/oficinafisica.css";
import { IoLocation } from "react-icons/io5";
import oficina1 from "../imagenes/oficina1.jpeg";
import oficina2 from "../imagenes/oficina2.jpeg";
import oficina3 from "../imagenes/oficina3.jpg";
import oficinaVirtual from "../imagenes/oficinavirtual.jpeg";

function OficinaFisica(props) {
  return (
    <div className="sedes">
      <img src={props.oficinafoto} className="imagenes-sedes" />
      <div className="info-sede">
        <h1 className="sedes-title">{props.titulo} </h1>
        <h3 className="sedes-title sedes-subtitle">{props.nombre}</h3>
        <p className="parrafo-sedes">{props.desc}</p>
        <div className="location-sedes">
          <div className="location-sedes-icon-div">
            <IoLocation />
          </div>
          <div className="location-sedes-direccion-div">
            <p>{props.direccion}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OficinaFisica;
