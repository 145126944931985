import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { InlineWidget } from "react-calendly";
import "../styles/success.css"
const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();

  
  useEffect(() => {
    const verifyToken = async () => {
      const params = new URLSearchParams(location.search);

      const token = params.get("token");

      if (!token) {
        navigate("/");
        return;
      }

      try {
        // Verify token with your server (adjust the URL as needed)
        const response = await axios.get(
          `https://estudioblasetti.onrender.com/verify_token?token=${token}`
        );
        if (response.data.isValid) {
          // Token is valid - proceed with success logic
          console.log("Transaction successful");
        } else {
          // Invalid token - redirect
          navigate("/");
        }
      } catch (error) {
        navigate("/");
      }
    };

    verifyToken();
  }, [location, navigate]);

  return (
    <div className="success-div">
      <h1>Su pago ha sido registrado con exito</h1>
      <h2>Reserve su turno seleccionando dia y hora a continuacion</h2>
      <div
        className="calendly-div"
        style={{ minWidth: "320px", height: "650px" }}
      >
        <InlineWidget url="https://calendly.com/estudioblasetti" />
      </div>
    </div>
  );
};

export default Success;
