import React from "react";
import "../styles/footer.css";
import {
  FaInstagram,
  FaFacebook,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaWhatsapp,
} from "react-icons/fa";
import Netriup from "./Netriup";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function Footer() {
  return (
    <div className="footer-container-div">
      <div className="footer-content">
        <div className="footer-section">
          <h2>Redes</h2>
          <div className="center-social-icons">
            <div className="social-icons">
              <div className="icons-socials instagram-icon">
                <a href="https://www.instagram.com/estudioblasetti/">
                  <FaInstagram className="icon-size-large" />
                </a>
              </div>
              <div className="icons-socials facebook-icon">
                <a href="https://www.facebook.com/profile.php?id=61563818252126">
                  <FaFacebook className="icon-size-large" />
                </a>
              </div>
              <div className="icons-socials whatsapp-icon-footer">
                <a href="#">
                  <FaWhatsapp className="icon-size-large" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-section">
          <h2>Contacto</h2>
          <ul>
            <div className="info-footer">
              <li>
                <FaPhone /> (221) 465 - 4039
              </li>
            </div>
            <div className="info-footer">
              <li>
                <FaEnvelope /> estudioblasetti@gmail.com
              </li>
            </div>
            <div className="info-footer">
              <li>
                <FaMapMarkerAlt /> Calle 14 no. 765 e. 47 y 48, La Plata, Bs.As
              </li>
            </div>
            <div className="info-footer">
              <li>
                <FaMapMarkerAlt /> Diag. 3 Jorge Bell no.446 entre 471 y 472
                City Bell
              </li>
            </div>
            <div className="info-footer">
              <li>
                <FaMapMarkerAlt /> Calle 158 no. 1415 entre 518 y 517 Melchor
                Romero
              </li>
            </div>
          </ul>
        </div>
      </div>

      <div className="go-to-top">
        <a onClick={scrollToTop} style={{ cursor: "pointer" }}>
          Volver arriba
        </a>
      </div>
      <Netriup />
    </div>
  );
}

export default Footer;
