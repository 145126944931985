import React from "react";
import "../styles/netriup.css";
const Netriup = () => {
  const redirectToNetriup = () => {
    window.location.href = "https://www.netriup.com";
  };
  return (
    <div className="outside-div-netriup" onClick={redirectToNetriup}>
      <div className="netriup-info">
        <h3 className="netriup-frase">Página web realizada por </h3>
        <h3 className="netriup-nombre">Netriup</h3>
      </div>
    </div>
  );
};

export default Netriup;
