import React, { useState, useEffect } from "react";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import axios from "axios";
import "../styles/mercadopago.css";


const MercadoPago = (props) => {
  const [preferenceId, setPreferenceId] = useState(null);
  const SERVER_URL = "https://estudioblasetti.onrender.com"

  const [tituloDeCompra, setTituloDeCompra] = useState(props.titulo);
  const [cantidad, setCantidad] = useState(props.cantidad);
  const [precio, setPrecio] = useState(props.precio);

  useEffect(() => {
    handleBuy();
  }, []);

  useEffect(() => {
    initMercadoPago("APP_USR-7720046c-914d-4447-8d0c-b0211393ea9e", {
      locale: "es-AR",
    });
    // Any other initialization code
  }, []);

  const customization = {
    visual: {
      borderRadius: "30px",
      horizontalPadding: "100px", // Adjust this value as needed
    },
  };

  const createPreference = async () => {

    try {
      const response = await axios.post(
        SERVER_URL + "/create_preference",
        {
          title: tituloDeCompra,
          quantity: 1,
          price: precio,
        }
      );
      const { id } = response.data;
      console.log("response.data", response.data);
      return id;
    } catch (error) {
      console.log(error);
    }
  };

  const handleBuy = async () => {
    const id = await createPreference();
    if (id) {
      setPreferenceId(id);
    }
  };
  return (
    <div className="outer-div-mp">
      <div className="button-pagar-mp">
        <div id="wallet_container">
          {preferenceId && (
            <Wallet
              initialization={{ preferenceId: preferenceId }}
              customization={{
                texts: { valueProp: "smart_option" },
                visual: customization.visual,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MercadoPago;
