import oficina1 from "../imagenes/oficina1.jpeg";
import oficina2 from "../imagenes/oficina2.jpeg";
import oficina3 from "../imagenes/oficina3.jpg";
import estudioblasetticitybell1 from "../imagenes/estudioblasettioficinacitybell1.jpeg";
import estudioblasetticalle14 from "../imagenes/estudioblasetticalle14.jpeg";
import oficinaVirtual from "../imagenes/oficinavirtual.jpeg";

const oficinasFisicasLista = [
  {
    id: 1,
    titulo: `Estudio Palacio de Justicia`,
    nombre: 'Antonio "Coco" Blasetti',
    desc: `En el corazón de la ciudad, un espacio pensado para la contención
              del cliente y su problemática, estructurado para el diálogo
              franco.`,
    direccion: `Calle 14 no. 765 entre 47 y 48 La Plata`,
    oficinafoto: estudioblasetticalle14,
  },
  {
    id: 2,
    titulo: `Estudio City Bell`,
    nombre: 'Ernesto "Meneco" Blasetti',
    desc: `              Pensado bajo un concepto que amplia el espacio jurídico de la
              problemática familiar a una mirada holistica en un entorno verde.`,
    direccion: `Diag. 3 Jorge Bell no.446 entre 471 y 472 City Bell`,
    oficinafoto: estudioblasetticitybell1,
  },
  {
    id: 3,
    titulo: `Estudio Melchor Romero`,
    nombre: "Jorge Blasetti",
    desc: `              Próximo, buscando llegar a las personas con un concepto de
    vecindad y cercanía.`,
    direccion: `Calle 158 no. 1415 entre 518 y 517 Melchor Romero`,
    oficinafoto: oficina3,
  },
  {
    id: 4,
    titulo: `Oficina Virtual`,
    nombre: "Oficina Virtual Jorge Blasetti",
    desc: `Cuando di en el año 2000 la primer conferencia sobre firma digital
    en el Colegio de Abogados de La Plata, no solo adelantaba una
    realidad de 15 años en la abogacía, sino que se ponía en la
    vanguardia de la conectividad digital tanto para ejercer el
    derecho, como para su difusión, en especial a los nativos
    digitales, un espacio que permite romper las limitaciones
    espaciales y modales para estar con nuestros clientes donde ellos
    estén.`,
    direccion: `Modalidad Virtual - Videollamada`,
    oficinafoto: oficinaVirtual,
  },
];

export default oficinasFisicasLista;
