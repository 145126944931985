import React from "react";
import "../styles/sanivo.css";
import sanivo from "../imagenes/sanivo.jpeg";
const Sanivo = () => {
  return (
    <div className="outer-div-sanivo">
      <div className="div-sanivo">
        <h1 className="titulo-funcaion-san-ivo">FUNDACION SAN IVO</h1>
        <h2 className="subtitulo-san-ivo"> (en constitución)</h2>
        <p className="parrafo-san-ivo"> 
          La fundación San Ivo (patrono de los abogados en muchos países), se
          constituye para que abogados católicos, puedan practicar obras
          caritativas en base a su expertís de incumbencia, y conforme a las
          normas de conducta del decálogo de los abogados del santo, permitiendo
          a personas con necesidades jurídicas, recurrir a consultoría de
          calidad, brindada por profesionales en el ejercicio liberal de la
          profesión. Los beneficiarios de la fundación, tiene la posibilidad de
          acceder a dos niveles de servicios inicialmente: el primero es la
          consulta San Ivo, presencial o virtual, con las formas y requisitos de
          admisibilidad que se establezcan para la misma, consiste en llevar al
          profesional, las circunstancias de su situación jurídica en una
          entrevista pautada y recibir una devolución oral orientativa; y el
          segundo, el acceder a una beca (total o parcial) San Ivo, con las
          formas y requisitos de admisibilidad que se establezcan para la misma,
          para llevar adelante la búsqueda de solución jurídica que lo aqueja.
          De esta manera la <b>FUNDACION SAN IVO</b> tiene cinco actores importantes,
          los abogados católicos que adhieran a la misma, la administración de
          la fundación, los beneficiaros y los aportantes que faciliten recursos
          para el funcionamiento de la fundación y la asistencia del Paráclito.
        </p>
        <img className="san-ivo-imagen" src={sanivo} />
        <h2> San Ivo de Kermartin – Abogado (1253-1303)</h2>
        <p className="parrafo-san-ivo">
          Nació el 7 de octubre de 1253 en Kermestín, Baja Bretaña, Francia.
          Cursó estudios de derecho y teología en las universidades de París y
          Orleans doctorándose en ambas facultades. Dedicó mucho tiempo diario a
          la oración, se abstuvo de comer carne y de las bebidas alcohólicas.
          Vestía pobremente y lo que ahorraba lo dedicaba a ayudar a los pobres.
          De regreso a su tierra natal, fue nombrado juez del tribunal y
          protegió a huérfanos, defendió a los más pobres y administró justicia
          con imparcialidad y bondad. Es llamado el "Abogado de los pobres".
          Visitó cárceles llevando regalos a los presos y ofreció memoriales de
          defensa a los que no podía conseguirse un abogado. Fue Vicario y Juez
          eclesiástico en Rennes y Treguier. Fue ordenado sacerdote, y desde
          entonces se dedicó a la predicación y a la administración de los
          sacramentos. Se cuenta que un rico demandó a un pobre porque olía
          diariamente las sabrosas emanaciones de su cocina señorial; Ivo,
          admitió la demanda y falló en su favor, condenando al pobre a pagar
          una moneda de oro. Cuando la hizo sonar sobre la mesa y, el rico iba a
          tomarla; decretó que el sonido de la moneda indemnizaba cumplidamente
          al señor por los aromas percibidos por el pobre. San Ivo falleció en
          Louannec el 11 de mayo de 1303. Canonizado por Clemente VI en 1347.
          Tiene su fiesta los 19 de Mayo.
        </p>
        <h2>Decálogo del abogado de San Ivo</h2>
        <div className="decalogo-lista-div">

        <p>
          <b>1º</b> Ningún abogado aceptará la defensa de casos injustos, porque son
          perniciosos para la conciencia y el decoro.
          <br></br>
          <br></br>
          <b>2º</b> El abogado no debe cargar al cliente con gastos exagerados.
          <br></br>
          <br></br>
          <b>3º</b> Ningún abogado debe defender causas valiéndose de medios ilícitos o
          injustos.
          <br></br>
          <br></br>
          <b>4º</b> Debe tratar los casos de todos los clientes como si fueran propios.
          <br></br>
          <br></br>
          <b>5º</b> No debe ahorrar trabajo ni tiempo para obtener el triunfo del caso
          que le ha sido encomendado.
          <br></br>
          <br></br>
          <b>6º</b> Ningún abogado debe aceptar más querellas de las que su tiempo
          disponible le permita.
          <br></br>
          <br></br>
          <b>7º</b> El abogado debe amar la justicia y la honradez, tanto como a las
          propias niñas de sus ojos.
          <br></br>
          <br></br>
          <b>8º</b> La demora y la negligencia de un abogado a menudo causa perjuicio
          al cliente, y cuando esto acontece el abogado debe indemnizarlo.
          <br></br>
          <br></br>
          <b>9º</b> Si un abogado pierde un caso debido a su negligencia, debe
          recompensar debidamente al cliente perjudicado.
          <br></br>
          <br></br>
          <b>10º</b> Para hacer una buena defensa el abogado debe ser verídico, sincero
          y lógico.
          <br></br>
          <br></br>
          <b>11º</b> Un abogado debe pedir ayuda a Dios en sus defensas, pues Dios es
          el primer protector de la justicia.
          <br></br>
          <br></br>
          <b>12º</b> Los principales requisitos de un abogado son: sabiduría, estudio,
          diligencia, verdad y sentido de justicia.
        </p>
        </div>
      </div>
    </div>
  );
};

export default Sanivo;
