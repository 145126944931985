import React, { useState } from 'react';
import "../styles/nuevocliente.css"
import Axios from 'axios';
const NuevoClienteForm = (props) => {
  const [demandanteName, setDemandanteName] = useState('');
  const [demandanteLastName, setDemandanteLastName] = useState('');
  const [demandanteDni, setDemandanteDni] = useState(0);
  const [demandanteDomicilio, setDemandanteDomicilio] = useState('');
  const [demandanteTelefono, setDemandanteTelefono] = useState(0);
  const [demandanteEmail, setDemandanteEmail] = useState('');
  const [demandanteCaratula, setDemandanteCaratula] = useState('');
  const [numeroDeCausa, setNumeroDeCausa] = useState('');
  const [demandadoName, setDemandadoName] = useState('');
  const [demandadoLastName, setDemandadoLastName] = useState('');
  const [demandadoDni, setDemandadoDni] = useState(0);
  const [demandadoDomicilio, setDemandadoDomicilio] = useState('');
  const [demandadoTelefono, setDemandadoTelefono] = useState(0);
  const [demandadoEmail, setDemandadoEmail] = useState('');
  const [demandadoJuzgado, setDemandadoJuzgado] = useState('');
  const [tieneMev, setTieneMev] = useState(false);
  const [tieneConvenio, setTieneConvenio] = useState(false);
  const [causaDe, setCausaDe] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [setDeBusqueda, setSetDeBusqueda] = useState(false);
  const [iusPrevisional, setIusPrevisional] = useState(false);
  const [bonoLey, setBonoLey] = useState(false);
  const [taza, setTaza] = useState(false);
  const [sobreTaza, setSobreTaza] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const BACKEND_URL = "https://estudioblasetti.onrender.com";

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation checks
    if (!numeroDeCausa) {
      setValidationMessage("Falta escribir numero de causa");
      return;
    }
    if (!demandadoJuzgado) {
      setValidationMessage("Falta seleccionar juzgado");
      return;
    }
    if (!causaDe) {
      setValidationMessage("Falta seleccionar causa de");
      return;
    }
    setValidationMessage('');
    setIsSubmitted(true); // Set to true after successful submission


    Axios.post(BACKEND_URL + "/createeb",
      {
        demandanteName,
        demandanteLastName,
        demandanteDni,
        demandanteDomicilio,
        demandanteTelefono,
        demandanteEmail,
        demandanteCaratula,
        numeroDeCausa,
        demandadoName,
        demandadoLastName,
        demandadoDni,
        demandadoDomicilio,
        demandadoTelefono,
        demandadoEmail,
        demandadoJuzgado,
        tieneMev,
        tieneConvenio,
        causaDe,
        descripcion,
        setDeBusqueda,
        iusPrevisional,
        bonoLey,
        taza,
        sobreTaza,
      },
      { headers: { Authorization: props.passwordUsr } }
    )
      .catch((error) => {
        console.error("Error creating client:", error);
        console.error("Error with Axios.post:", error); // Logs error to console

      });
  }
  return (
    <form onSubmit={handleSubmit} className="client-form">
      {validationMessage && <p className="validation-message">{validationMessage}</p>}

      <div className="form-section">
        <h3>Nombre completo cliente:</h3>
        <input type="text" id="name-demandante" onChange={(e) => setDemandanteName(e.target.value)} className="input-field" />

        <h3>Apellido cliente:</h3>
        <input type="text" id="last-name-demandante" onChange={(e) => setDemandanteLastName(e.target.value)} className="input-field" />

        <h3>DNI cliente:</h3>
        <input type="text" id="dni-demandante" onChange={(e) => setDemandanteDni(e.target.value)} className="input-field" />

        <h3>Domicilio cliente:</h3>
        <input type="text" id="domicilio-demandante" onChange={(e) => setDemandanteDomicilio(e.target.value)} className="input-field" />

        <h3>Telefono cliente:</h3>
        <input type="text" id="telefono-demandante" onChange={(e) => setDemandanteTelefono(e.target.value)} className="input-field" />

        <h3>Email cliente:</h3>
        <input type="text" id="email-demandante" onChange={(e) => setDemandanteEmail(e.target.value)} className="input-field" />

        <h3>Caratula cliente:</h3>
        <input type="text" id="caratula-demandante" onChange={(e) => setDemandanteCaratula(e.target.value)} className="input-field" />

        <h3>Numero de causa:</h3>
        <input type="text" id="numero-de-causa" onChange={(e) => setNumeroDeCausa(e.target.value)} className="input-field" />
      </div>

      <div className="form-section">
        <h3>Nombre Contraparte:</h3>
        <input type="text" id="name-demandado" onChange={(e) => setDemandadoName(e.target.value)} className="input-field" />

        <h3>Apellido contraparte:</h3>
        <input type="text" id="last-name-demandado" onChange={(e) => setDemandadoLastName(e.target.value)} className="input-field" />

        <h3>DNI contraparte:</h3>
        <input type="text" id="dni-demandado" onChange={(e) => setDemandadoDni(e.target.value)} className="input-field" />

        <h3>Domicilio contraparte:</h3>
        <input type="text" id="domicilio-demandado" onChange={(e) => setDemandadoDomicilio(e.target.value)} className="input-field" />

        <h3>Telefono contraparte:</h3>
        <input type="text" id="telefono-demandado" onChange={(e) => setDemandadoTelefono(e.target.value)} className="input-field" />

        <h3>Email contraparte:</h3>
        <input type="text" id="email-demandado" onChange={(e) => setDemandadoEmail(e.target.value)} className="input-field" />
      </div>

      <div className="form-section">
        <h3>Causa de:</h3>
        <select id="causa-de" value={causaDe || ""} onChange={(e) => setCausaDe(e.target.value)} className="select-field">
          <option value="">Seleccione dueño de causa</option>
          <option value="ANA">ANA</option>
          <option value="JORGE">JORGE</option>
          <option value="MARIA">MARIA</option>
          <option value="ALEJANDRA">ALEJANDRA</option>
          <option value="PASANTES">PASANTES</option>
          {/* Add other options */}
        </select>
        <h3>Juzgado</h3>
        <select id="juzgado-demandado" value={demandadoJuzgado || ""} onChange={(e) => setDemandadoJuzgado(e.target.value)} className="select-field">
          <option value="">Seleccione juzgado</option>
          <option value="F1">F1</option>
          <option value="F2">F2</option>
          <option value="F3">F3</option>
          <option value="F4">F4</option>
          <option value="F5">F5</option>
          <option value="F6">F6</option>
          <option value="F7">F7</option>
          <option value="F8">F8</option>
          {/* Add other options here */}
        </select>
      </div>

      <div className="form-section checkboxes">
        <h3>Set de busqueda?</h3>
        <input type="checkbox" id="setdebusqueda" onChange={(e) => setSetDeBusqueda(e.target.checked)} className="checkbox" />

        <h3>Tiene mev?</h3>
        <input type="checkbox" id="tiene-mev" onChange={(e) => setTieneMev(e.target.checked)} className="checkbox" />

        <h3>Tiene convenio?</h3>
        <input type="checkbox" id="tiene-convenio" onChange={(e) => setTieneConvenio(e.target.checked)} className="checkbox" />

        <h3>Ius Previsional?</h3>
        <input type="checkbox" id="iusprevisional" onChange={(e) => setIusPrevisional(e.target.checked)} className="checkbox" />

        <h3>Bono Ley?</h3>
        <input type="checkbox" id="bonoley" onChange={(e) => setBonoLey(e.target.checked)} className="checkbox" />

        <h3>Taza?</h3>
        <input type="checkbox" id="taza" onChange={(e) => setTaza(e.target.checked)} className="checkbox" />

        <h3>Sobre Taza?</h3>
        <input type="checkbox" id="sobretaza" onChange={(e) => setSobreTaza(e.target.checked)} className="checkbox" />


      </div>

      {validationMessage && <p style={{ color: 'red' }}>{validationMessage}</p>}
      {isSubmitted && <p className="success-message">Cliente agregado con éxito</p>}

      <button type="submit" className="submit-button">Enviar</button>
    </form>
  );
};

export default NuevoClienteForm;
