import React from "react";
import oficinaVirtual from "../imagenes/oficinavirtual.jpeg";
import oficinasFisicasLista from "../informacion/oficinasFisicasLista";

import "../styles/oficinas.css";

import { IoLocation } from "react-icons/io5";
import OficinaFisica from "./OficinaFisica";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Oficinas = () => {
  const navigate = useNavigate();
  const [oficinasLista, setIntegrantesLista] = useState(oficinasFisicasLista);
  const [oficinasListaVirtual, setIntegrantesListaVirtual] =
    useState(oficinasFisicasLista);
  const firstOne = oficinasLista.slice(0, 3);
  const secondOne = oficinasLista.slice(3, 4);

  return (
    <div className="container-oficinas">
      <div className="container-tres-imagenes">
        {firstOne.map((oficina) => {
          const { id, titulo, nombre, desc, direccion, oficinafoto } = oficina;
          return (
            <div key={id} onClick={() => navigate("/oficina/" + id)}>
              <OficinaFisica
                titulo={titulo}
                nobmre={nombre}
                desc={desc}
                direccion={direccion}
                oficinafoto={oficinafoto} // Assuming integrante4 is defined elsewhere
              />
            </div>
          );
        })}
      </div>

      <div className="sede-virtual-outside-div">
        <div className="sede-virtual">
          {secondOne.map((oficina) => {
            const { id, titulo, nombre, desc, direccion, oficinafoto } =
              oficina;
            return (
              <div key={id} onClick={() => navigate("/oficina/" + id)}>
                <img src={oficinaVirtual} className="sede-virtual-imagen" />
                <h1 className="sedes-title">Estudio Virtual</h1>
                <h3 className="sedes-title sedes-subtitle">
                  Jorge Juan Blasetti
                </h3>
                <p className="parrafo-sedes virtual">
                  Cuando di en el año 2000 la primer conferencia sobre firma
                  digital en el Colegio de Abogados de La Plata, no solo
                  adelantaba una realidad de 15 años en la abogacía, sino que se
                  ponía en la vanguardia de la conectividad digital tanto para
                  ejercer el derecho, como para su difusión, en especial a los
                  nativos digitales, un espacio que permite romper las
                  limitaciones espaciales y modales para estar con nuestros
                  clientes donde ellos estén.
                </p>
                <div className="virtual-location-address">
                  <div className="location-sedes virtual-location">
                    <div className="location-sedes-icon-div">
                      <IoLocation />
                    </div>

                    <div className="location-sedes-direccion-div">
                      <p>Atencion online a travez de video llamada</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Oficinas;
