import React from "react";
import { InlineWidget } from "react-calendly";
import "../styles/oficinavirtual.css";
import MercadoPago from "./MercadoPago";

function Oficinavirtual() {
  return (
    <div className="outside-oficina-virtual">
      <h1 className="oficina-virtual-titulo">Oficina Virtual</h1>

      <MercadoPago precio={1000} titulo={"hoal"} cantidad={1} />
      <div className="calendly-div">
        <InlineWidget
          url="https://calendly.com/estudioblasetti"
          style="min-width:320px;height:650px;"
        />
      </div>
    </div>
  );
}

export default Oficinavirtual;
