import integrante1 from "../imagenes/papafrontal.jpeg";
import integrante2 from "../imagenes/alefrontal.jpeg";
import integrante3 from "../imagenes/grandefrontal.jpeg";
import integrante4 from "../imagenes/anabellafrontal.jpeg";
import integrante5 from "../imagenes/crisfrontal.jpeg";
import integrante6 from "../imagenes/abogado1.jpeg";
import integrante7 from "../imagenes/castrofrontal.jpeg";
import integrante8 from "../imagenes/frontalhugo.jpeg";
import integrante9 from "../imagenes/abogacia1.jpg";
import integrante10 from "../imagenes/abogacia2.jpg";

const integrantesLista = [
  {
    id: 1,
    nombre: "Jorge Juan Blasetti",
    titulo: "Abogado",
    desc: `Facultad de Ciencias Jurídicas y Sociales, Universidad Nacional de La Plata `,
    desc2: `Derecho de la familia y animal`,
    imgName: integrante1,
    specialties: [0, 1, 3], // Assuming these are the IDs of services this lawyer specializes in
  },
  {
    id: 2,
    nombre: "Maria Alejandra Merlo",
    titulo: "Abogada, Escribana",
    desc: `Facultad de Ciencias Sociales, Universidad Nacional de La Plata. `,
    desc2: `Ex asesora de incapaces de Quilmes`,
    imgName: integrante2,
    specialties: [2, 4, 5],
  },

  {
    id: 3,
    nombre: "Osvaldo Chamas",
    titulo: "Abogado",
    desc: `Facultad de Ciencias Jurídicas y Sociales`,
    desc2: `Derecho Civil`,
    imgName: integrante3,
    specialties: [0, 2, 4, 6],
  },
  {
    id: 4,
    nombre: "Anabella Belen Alvarez",
    titulo: "Abogada",
    sedeResponsable: `Sede M. Romero 'Jorge Blasetti'`,
    desc: `Facultad de Ciencias Jurídicas y Sociales, Universiad Nacional de La Plata`,
    desc2: ``,
    imgName: integrante4,
    specialties: [1, 4, 5],
  },
  {
    id: 5,
    nombre: "Cris Melina Balbuena",
    titulo: "Abogada",
    sedeResponsable: `Sede Estudio Palacio de la Justicia "Anotonio 'Coco' Blasetti"`,
    desc: `Facultad de Ciencias Jurídicas y Sociales`,
    desc2: ` `,
    imgName: integrante5,
    specialties: [2, 3, 5],
  },

  {
    id: 6,
    nombre: "Nombre Apellido",
    titulo: "Abogado",
    sedeResponsable: `Sede Estudio City Bell "Ernesto 'Meneco' Blasetti"`,
    desc: `Facultad de Ciencias Jurídicas y Sociales
        Universidad Nacional de La Plata `,
    desc2: ``,
    imgName: integrante6,
    specialties: [2, 5],
  },
  {
    id: 7,
    nombre: "Martin Alejandro Castro",
    titulo: "Abogado",
    desc: `Facultad de Ciencias Jurídicas y Sociales
        Universidad Nacional de La Plata `,
    desc2: `Derecho Penal`,
    imgName: integrante7,
    specialties: [1],
  },
  {
    id: 8,
    nombre: "Hugo Horacio Balbuena",
    titulo: "Abogado",
    desc: `Facultad de Ciencias Jurídicas y Sociales
        Universidad Nacional de La Plata `,
    desc2: `Derecho Civil`,
    imgName: integrante8,
    specialties: [4, 5],
  },

  {
    id: 9,
    nombre: "Gerardo Elias Salmo",
    titulo: "Abogado",
    desc: ``,
    desc2: ``,
    imgName: integrante9,
  },
  {
    id: 10,
    nombre: "Otro Abogado",
    titulo: "Abogado",
    desc: ``,
    desc2: ``,
    imgName: integrante10,
  },
];

export default integrantesLista;
