import React, { useState, useEffect } from "react";
import sliderImagen1 from "../imagenes/abogacia1.jpeg";
import sliderImagen2 from "../imagenes/abogacia2.jpg";
import sliderImagen3 from "../imagenes/abogacia3.jpg"; // Add the path for your third image
import "../styles/slideshow.css";

function Slideshow() {
  const images = [sliderImagen1, sliderImagen2, sliderImagen3];
  const [index, setIndex] = useState(0);

  const goToPrevious = () => {
    setIndex(index === 0 ? images.length - 1 : index - 1);
  };

  const goToNext = () => {
    setIndex(index === images.length - 1 ? 0 : index + 1);
  };

  // Automatic sliding
  useEffect(() => {
    const timer = setInterval(() => {
      goToNext(); // change to next image
    }, 3000); // change every 3 seconds

    return () => clearInterval(timer); // clear interval on component unmount
  }, [index]);

  return (
    <div className="slideshow">
      <button className="slide-button left" onClick={goToPrevious}>&lt;</button>
      <img src={images[index]} className="imagen-banner" alt="Slideshow" />
      <button className="slide-button right" onClick={goToNext}>&gt;</button>

      <div className="slideshow-dots">
        {images.map((_, idx) => (
          <span
            key={idx}
            className={`dot ${index === idx ? 'active' : ''}`}
            onClick={() => setIndex(idx)}
          ></span>
        ))}
      </div>
    </div>
  );
}

export default Slideshow;
