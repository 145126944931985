import React, { useEffect } from "react";
import Slideshow from "./Slideshow";
import "../styles/home.css";
import ReactGA from 'react-ga4';
import { Link } from "react-router-dom";
import ScrollingMarqueeText from "./ScrollingMarqueeText";

const TRACKING_ID = "G-CL7WMN75JE";
function Home() {

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    console.log("useEffect Google Analytics")
    ReactGA.send({ hitType: "pageview", page: "/home", title: "Home Page" });
}, [])

  return (
    <div className="container-home">
      <div className="container">
        <div className="titulo">
          <h1 className="titulo-principal">Estudio Blasetti</h1>
          <h3 className="subtitulo">Abogados de Familia</h3>
          <h3 className="subtitulo">desde 1928</h3>
          <p className="texto">
            Desde que Furor Antonio se dedico al ejecicio del derecho y su
            divulgación, en los años veinte del siglo pasado, sus hijos Antonio,
            Ernesto y Jorge abrazaron la profesión con identica pasión y
            compromiso en el area civil. Desde las visperas del siglo
            XXI, la tercer generación Blasetti, representada por Jorge Juan
            junto con profesionales asociados, mantienen el rumbo, enfocados
            especialmente a la problematica de las familias, en pos de hallar
            soluciones adecuadas para su vida.
            <br />
            <br /> El Estudio fundado por Furor Antonio, tuvo a lo largo de mas
            noventa de historia distintas oficinas en La Plata y en Olivos. Hoy
            contamos con cuatro sedes, tres oficinas físicas y una virtual para
            estar cerca de su amplia cartera de clientes y amigos, para el
            ejercicio liberal de la profesion de abogar.
            <br />
            <br /> La abogacía liberal, acompaña al cliente en el tránsito de su
            problemática particular, en forma preventiva o en proceso judicial, siendo un
            consejero y un gestor de la decisión del cliente. Poniendo a
            disposición su saber y experiencia jurídica y de profesionales de
            otras ramas, muchas veces necesarios a la hora de tomar resoluciones
            de impacto en su futuro.
            <br />
            <br />
            Nuestra invitación es a conocernos y estar en su consideración al
            momento de necesitar de los servicios de asistencia a la
            problemática jurídico familiar, de negocios o personal.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
