import React from "react";
import "../styles/vinculados.css";
import imagen1 from "../imagenes/sanivo.jpeg"
import imagen2 from "../imagenes/vinculados/picaditaslogo.png"
import imagen3 from "../imagenes/vinculados/pizzascitybell.jpg"
import imagen4 from "../imagenes/vinculados/Netriup.png"
import imagen5 from "../imagenes/vinculados/Con Pasion.png"
import imagen6 from "../imagenes/vinculados/Puntoderetiro.png"
const Vinculados = () => {
  return (
    <div className="vinculados-section-div">
      <div className="vinculados">
        <h1 className="vinculados-title">Vinculados</h1>
        <h2 className="vinculados-p">
          Un espacio en el que les compartimos nuestros clientes y asociados
        </h2>
        <div className="outside-div-lista-de-vinculados">
          <div className="list-of-vinculados">
            <div className="ind-marca-div">
              <h3>Punto De Retiro</h3>
              <img src={imagen6} className="imagen-marca" />
            </div>
            <div className="ind-marca-div">
              <h3>Picaditas</h3>
              <img src={imagen2} className="imagen-marca" />
            </div>
            <div className="ind-marca-div">
              <h3>Netriup</h3>
              <img src={imagen4} className="imagen-marca" />
            </div>
          </div>
          <div className="list-of-vinculados">
            <div className="ind-marca-div">

              <h3>Fundacion San Ivo</h3>
              <img src={imagen1} className="imagen-marca" />
            </div>
            <div className="ind-marca-div">
              <h3>Pizzas City Bell</h3>
              <img src={imagen3} className="imagen-marca" />
            </div>
            <div className="ind-marca-div">
              <h3>Pastas con Pasion</h3>
              <img src={imagen5} className="imagen-marca" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vinculados;
