import React, { useState } from "react";
import ServicioOpcion from "./ServicioOpcion";

const RightServicio = ({ id, title, descripcion, precio, precio2 }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div className="outside-div-servicio">
      <h1 className="titulo-servicio">{title}</h1>
      <h1 className="descripcion-servicio">{descripcion}</h1>

      <br />
      {!showOptions && (
        <button
          className="botones-opciones-servicios"
          onClick={() => setShowOptions(true)}
        >
          Obtener Consulta
        </button>
      )}
      {showOptions && (
        <>
          <h2 className="descripcion-inferior-servicio">
            Seleccione el tipo de consulta de {title}
          </h2>
          <div className="opciones-dentro-de-servicios">
            <button
              className={`botones-opciones-servicios ${
                selectedOption === "Presencial" ? "selectedOption" : "not-selected"
              }`}
              onClick={() => setSelectedOption("Presencial")}
            >
              Presencial
            </button>
            <button
              className={`botones-opciones-servicios ${
                selectedOption === "Presencial Extendida" ? "selectedOption" : "not-selected"
              }`}
              onClick={() => setSelectedOption("Presencial Extendida")}
            >
              Presencial Extendida
            </button>
            <button
              className={`botones-opciones-servicios ${
                selectedOption === "Virtual" ? "selectedOption" : "not-selected"
              }`}
              onClick={() => setSelectedOption("Virtual")}
            >
              Virtual
            </button>
          </div>
        </>
      )}

      <div className="opciones-servicios">
        <ServicioOpcion
          selectedOption={selectedOption}
          precio={precio}
          precio2={precio2}
          title={title}
          servicioId={id}
        />
      </div>
    </div>
  );
};

export default RightServicio;
