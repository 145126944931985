const blogs = [
  {
    id: 1,
    titulo: `Vulnerables`,
    categoria: `Derecho de Familia`,
  },
  // Agrega más objetos con sus respectivas categorías
  {
    id: 2,
    titulo: `Conservación de especies`,
    categoria: `Derecho Animal`,
  },
  // Sigue expandiendo la lista según sea necesario
];

export default blogs;
