import React, { useState, useEffect } from "react";
import serviciosLista from "../informacion/serviciosLista"; // Correct path to your services list
import integrantesLista from "../informacion/integrantes"; // Correct path to your lawyers list

function LawyerMatcher({ servicioSeleccionado }) { // Correctly extract props
  const [selectedService, setSelectedService] = useState(servicioSeleccionado);
  const [filteredLawyers, setFilteredLawyers] = useState([]);

  useEffect(() => {
    // Assuming `servicioSeleccionado` is an ID, you might need to convert it to a number
    // especially if it's coming as a string (e.g., from a URL or user input)
    const serviceId = Number(selectedService); // Convert to number if necessary

    const filtered = integrantesLista.filter((lawyer) =>
      lawyer.specialties && lawyer.specialties.includes(serviceId) // Check for undefined and use correct ID
    );
    setFilteredLawyers(filtered);
  }, [selectedService]);

  return (
    <div>
      {
        /*
        <h2>Available Lawyers</h2>
      <ul>
        {filteredLawyers.length > 0 ? (
          filteredLawyers.map((lawyer) => (
            <li key={lawyer.id}>
              {lawyer.nombre} - {lawyer.titulo}
            </li>
          ))
        ) : (
          <li>No lawyers available for this service</li>
        )}
      </ul>
      */
      }
    </div>
  );
}

export default LawyerMatcher;
